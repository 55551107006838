/**
 * 分页类
 */
class paging {
    constructor(){
        this.arr = [];//全部数据数组
        this.pageSzie = {};//设置
        this.pageNum = 1;
    }

    /**
     * 初始化分页类
     * @param data 表格全部数据
     * @param size 每页显示数量
     */
    init(data,size = 30){
        this.arr = data;
        this.pageSzie = size;
        this.setPage(1);
        // console.log('paging.init',data.length,size)
    }

    /**
     * 设置当前页数
     * @param pageNum 页数
     */
    setPage(pageNum){
        this.pageNum = pageNum;
    }

    /**
     * 设置每页显示数量
     * @param size
     */
    setSize(size){
        this.pageSzie = size;
    }

    /**
     * 取当前页数数据
     * @returns {*[]}
     */
    getPageData(){
        let max = this.pageSzie * this.pageNum;
        let min = max - this.pageSzie;
        // console.log('getPageData',max,min)
        let res = this.arr.slice(min,max);
        return res;
    }

    /**
     * 取全部数据长度[成员数]
     * @returns {number}
     */
    getLength(){
        return this.arr.length;
    }

    /**
     * 取出全部数据[数组]
     * @returns {[]}
     */
    getAllData(){
        return this.arr;
    }


}

export default new paging();