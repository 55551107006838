<template>
    <div class="AgentLogFilterView" v-loading="isLoading">
        <el-dialog
            v-model="dialogVisible"
            title="Filter Log"
            width="30%"
        >
            <div class="Filter">
                <el-form @keydown.enter.prevent="disableEnterKey">
                    <el-form-item :label="lang['el-form-item.label']">
                        <el-input
                            v-model="FilterOptions.searchKey"
                            :placeholder="lang['el-form-item.placeholder']"
                        />
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="dialogVisible = false">
                    {{lang['el-button.return']}}
                </el-button>
                <el-button type="primary" @click="FilterLog">
                  {{lang['el-button.submit']}}
                </el-button>
              </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import {ref,defineExpose,toRaw,defineEmits} from "vue";
import {ElMessageBox} from "element-plus";
import common from "../../../utils/common";
import LangLib from '../../../lang/index';
const lang = ref(LangLib.getLang('log','agentLog-Filter'));

const dialogVisible = ref(false);
const OpenFilterLog = (LogArray) => {
    FilterOptions.value.searchKey = '';
    isLoading.value = false;
    dialogVisible.value = true;
    LogArr.value = LogArray;
}
defineExpose({
    OpenFilterLog
})

const LogArr = ref([]);
const FilterOptions = ref({
    searchKey:'relax1',
})
const emits = defineEmits(['FilterEvent']);
const isLoading = ref(false);
const FilterLog = () => {
    isLoading.value = true;
    let options = toRaw(FilterOptions.value);
    if (!options.searchKey){
        ElMessageBox.alert(lang.value['FilterLog.error.searchKey'])
        return;
    }
    let arr = toRaw(LogArr.value);
    let res = arr.filter(item=>FindStrObj(item,['object','TypeMsg','attach'],options.searchKey))
    emits('FilterEvent',res)
    isLoading.value = false;
    dialogVisible.value = false;
}

const FindStrObj = (obj,keyArr,find) => {
    let bool = false;
    for (let keyArrKey in keyArr) {
        let key = keyArr[keyArrKey];
        let str  = obj[key];
        if(str){
            if (common.checkExistString(str,find)){
                bool = true;
                break;
            }
        }
    }
    return bool ;
}

const disableEnterKey = (event) => {
    // console.log('disableEnterKey',event)
    if (event.keyCode === 13 || event.which === 13) {
        // 阻止默认行为
        event.preventDefault();
    }
}
</script>

<style scoped>

</style>