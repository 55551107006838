<!--查询指定代理商日志-->
<template>
    <div class="tools">
        <el-button :disabled="isLoading" @click="this.$router.go(0)">{{lang['html.el-button.refresh']}}</el-button>
        <el-button :disabled="isLoading" @click="OpenFilterLogView">{{lang['html.el-button.OpenFilterLogView']}}</el-button>
        <el-button :disabled="isLoading" @click="ChangeTableData(LogAllArr)">{{lang['html.el-button.ChangeTableData']}}</el-button>
<!--        <el-button :disabled="isLoading">筛选数据</el-button>-->
        <el-tag style="margin-left: 10px;" v-show="this.postData.agent != 'self'">{{lang['html.el-tag.agent']}}:{{this.postData.agent}}</el-tag>
    </div>

    <el-table :data="tableData" stripe style="width: 100%" v-loading="isLoading">
        <el-table-column prop="id" :label="lang['html.el-table.label.id']" width="180" />
        <el-table-column prop="time" :label="lang['html.el-table.label.time']" />
        <el-table-column prop="object" :label="lang['html.el-table.label.object']" />
        <el-table-column :label="lang['html.el-table.label.type']" width="180">
            <template #default="scope">
<!--                {{getTypeMsg(scope.row.type)}}-->
                {{scope.row.TypeMsg}}
            </template>
        </el-table-column>
        <el-table-column prop="attach" :label="lang['html.el-table.label.attach']" />
    </el-table>

    <AgentLogFilter
        ref="refsAgentLogFilter"
        @FilterEvent="FilterEvent"
    />

    <div style="text-align: left;display: inline">
        <el-pagination
            background
            layout="total,prev, pager, next,sizes"
            style="margin-top: 10px"

            :total=pages.total

            v-model:currentPage="pages.page"
            @current-change="changePage"

            v-model:page-size="pages.size"
            :page-sizes="[20,50,100,500,1000]"
            @size-change="handleSizeChange"
        />
    </div>

</template>

<script>
import paging from "../../utils/paging";
import AgentLogFilter from "./agentLog/agentLog-Filter";

export default {
    name: "agentLog",
    components: {AgentLogFilter},
    data(){
        return {
            postData:{
                agent:'self',
                langType:this.$lang.getLanguage() == 'zh' ? 1 :2,
            },
            lang:this.$lang.getLang('log','agentLog'),
            isLoading:true,
            tableData:[],
            pages:{
                total:0,
                size:20,
                page:1,
            },
            LogAllArr:[],
        }
    },
    methods:{
        getAgentLog(){
            this.isLoading = true;
            this.$notify({message:this.lang['getAgentLog.wait'],type:'info'});
            this.$api.post('AgentLog.Log/getAgentLogNew',this.postData).then(res=>{
                let data = this.$helper.checkRes(res,true);
                if (!data){
                    this.$alert(this.lang['getAgentLog.error.data']);
                    return;
                }
                let log = data.data;
                if (!log){
                    this.$alert(this.lang['getAgentLog.error.length']);
                    return;
                }
                // log = this.cleanLogData(log);
                let resultArr = log.map(item=>{
                    item.TypeMsg = this.getTypeMsg(item.type);
                    return item;
                });
                this.LogAllArr = resultArr;
                this.pages.total = resultArr.length;
                paging.init(resultArr,this.pages.size);
                this.handleSizeChange();
            }).finally(()=>this.isLoading = false);
        },
        //翻页
        changePage(){
            paging.setPage(this.pages.page);
            this.tableData = paging.getPageData();
        },
        //每页显示数量变动
        handleSizeChange(){
            this.pages.total = paging.getLength();
            this.pages.page = 1;
            paging.setSize(this.pages.size);
            this.changePage();
        },
        //重新整理日志[cleanLogData函数已废弃]
        cleanLogData(logArr){
            let res = [];
            let i = 0;
            for (let logArrKey in logArr) {
                i++;
                let temp = logArr[logArrKey];
                if (temp.version != null){
                    //mysql的日志
                    let field = 'logType.' + temp.type ?? 'un';
                    let TypeStr = this.lang[field] ?? this.lang['logType.un'];
                    let obj = temp.object !=null ? temp.object : '';
                    res.push({
                        id:i,
                        msg:obj.length >=2 ? TypeStr + ' ['+temp.object+']' : TypeStr,
                        time:temp.time,
                        attach:temp.attach,
                    });
                }else{
                    //易语言端的日志直接加入
                    res.push({
                        id:i,
                        msg:temp.msg,
                        time:temp.time,
                    });
                }
            }
            return res;
        },
        //获取日志类型[文本]
        getTypeMsg(type){
            let field = 'logType.' + type ?? 'un';
            let typeStr = this.lang[field] ?? this.lang['logType.un'] + ` Code:${type}`;
            if (Number(type) >= 100){
                typeStr += '*';
            }
            return typeStr;
        },
        OpenFilterLogView(){
            this.$refs.refsAgentLogFilter.OpenFilterLog(this.LogAllArr);
        },
        FilterEvent(arr){
            if (!arr){
                this.$alert(this.lang['FilterEvent.error.null'])
                return;
            }
            this.ChangeTableData(arr);
        },
        ChangeTableData(arr){
            this.$message({message:this.lang['ChangeTableData.length'] + arr.length});
            paging.init(arr,20);
            this.handleSizeChange();
        }
    },
    mounted() {
        let agent = this.$route.params.agent;
        if (agent != null){
            if (agent.length > 2){
                this.postData.agent = agent;
            }
        }
        this.getAgentLog();
    }
}
</script>

<style scoped>
.tools{
    margin-bottom: 10px;
}

</style>